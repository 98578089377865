import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import PublishIcon from '@mui/icons-material/Publish';
import { axiosErrorToUserMessage } from '../../services/axios';
import useSiteSettings from '../../hooks/useSiteSettings';
import { useApiClient } from '../../context/ApiClientContext';
import basicServiceHandler from '../../services/basicServiceHandler';
import { useIntakeData } from '../../context/IntakeDataContext';

function UploadToEMRButton(props) {
  const {
    mkey, setSuccessMessage, setErrorMessage,
  } = props;
  const { activeSiteId } = useSiteSettings();
  const apiClient = useApiClient();
  const { fetchIntakeData } = useIntakeData();
  if (!activeSiteId) return null;
  const [loading, setLoading] = useState(false);

  const uploadToEMR = async () => {
    setLoading(true);
    // get latest access Token from site setting using activeSiteId
    basicServiceHandler(
      () => apiClient.uploadToEMR(mkey),
      (response) => {
        fetchIntakeData();
        setSuccessMessage(response.data.message);
      },
      (error) => {
        setErrorMessage(
          axiosErrorToUserMessage(error) || 'Error pushing to EMR',
        );
      },
    );
    setLoading(false);
  };

  return (
    <Tooltip title="Upload to EMR" arrow>
      <span>
        <IconButton onClick={() => uploadToEMR()} data-testid="uploadToEmrIcon" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : <PublishIcon fontSize="small" />}
        </IconButton>
      </span>
    </Tooltip>
  );
}
export default UploadToEMRButton;

UploadToEMRButton.propTypes = {
  mkey: PropTypes.string.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};
