import React, { useState, useEffect } from 'react';
import {
  Button as MuiButton, Box, CircularProgress, Container,
  Grid,
  TextField,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useRollbar } from '@rollbar/react';
import Toast, { useToast } from '../../components/useToast';
import {
  axiosIntakeInstance,
  axiosCustomerInstance,
  axiosErrorToUserMessage,
} from '../../services/axios';
import ZoomableImage from '../../components/ZoomableImage';
import useSiteSettings from '../../hooks/useSiteSettings';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import BottomNavBar from '../Appbar/BottomNavBar';
import HtmlTextEntry from '../../components/HtmlTextEntry';
import OfferedTemplates from './IntakeReviewPage/OfferedTemplates';
import IntakeReviewPageHeader from './IntakeReviewPageHeader';
import Button from '../../components/controls/Button';
import Signature from './Signature';

export default function IntakeReviewPage() {
  const [intakeContent, setIntakeContent] = useState('');
  const [summary, setSummary] = useState('');
  const [attachedImages, setAttachedImages] = useState([]);
  const [templateOptions, setTemplateOptions] = useState({});
  const [showImages, setShowImages] = useState(false);
  const [canEditReport, setCanEditReport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [patientSupportEmailRecipient, setPatientSupportEmailRecipient] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isLlmSummaryEnabled, setIsLlmSummaryEnabled] = useState(false);
  const [patientInfo, setPatientInfo] = useState({
    patientSex: '',
    patientAge: '',
    patientID: '',
  });
  const [headerLogo, setHeaderLogo] = useState('/assets/images/FirstHx_symbol_colour.jpg');
  const [signature, setSignature] = useState({ signatureImageSrc: '', signatureQuestion: '' });
  const [
    generateLlmSummaryButtonLoading,
    setgenerateLlmSummaryButtonLoading,
  ] = useState(false);

  const navigate = useNavigate();

  const { mkey } = useParams();
  const { activeSiteId } = useSiteSettings();
  const { rollbar } = useRollbar();

  /* apiClient gives 401 auth error on page refresh. temp solution is to directly call
    useAxiosPrivate to bypass that error. update fn again once we figure out the bug */
  const intakeAxiosWithAuth = useAxiosPrivate(axiosIntakeInstance);
  const customerAxiosWithAuth = useAxiosPrivate(axiosCustomerInstance);

  const {
    toastState,
    toastSetSuccessNotification,
    toastSetErrorNotification,
    toastOnClose,
  } = useToast();

  const formatIntakeContentDisplay = (content) => {
    if (typeof content !== 'string') {
      rollbar.warn('"Invalid content" displayed', { mkey, content });
      return 'Invalid content';
    }

    if (content.trim() === '') {
      rollbar.warn('"No content available" displayed', { mkey, content });
      return 'No content available';
    }

    return content;
  };

  const extractPiiValue = (data, pattern) => {
    const regex = new RegExp(`${pattern}: (.*?)\\n`, 's');
    const match = data.match(regex);
    return match && match[1] ? match[1].trim() : null;
  };

  const fetchData = async () => {
    try {
      const response = await intakeAxiosWithAuth.get(`/${activeSiteId}/${mkey}/review`);
      const sex = extractPiiValue(response.data.report, 'Sex');
      const age = extractPiiValue(response.data.report, 'Age');
      const id = extractPiiValue(response.data.report, 'Patient ID');
      setPatientInfo((prevState) => ({
        ...prevState,
        patientSex: sex,
        patientAge: age,
        patientID: id,
      }));

      const formattedIntakeHtmlReport = formatIntakeContentDisplay(response.data.reportHtml);
      setIntakeContent(formattedIntakeHtmlReport);
      setAttachedImages(response.data.attachedFilesWithAccessToken?.map((file, index) => (
        { ...file, zoom: 1, index }
      )));
      setTemplateOptions(response.data.templatesToOffer);
      setSignature(response.data.signature);
      setSummary(response.data.reportLlmSummary);
    } catch (error) {
      toastSetErrorNotification(
        axiosErrorToUserMessage(error)
                || 'An error occurred when fetching the report.',
      );
    }
  };

  const fetchAndSetSiteConfigurations = async () => {
    try {
      const response = await customerAxiosWithAuth.get(`/${activeSiteId}`);
      const settings = response?.data?.settings || [];
      const canEditReportSetting = settings.find((setting) => setting.name === 'canEditReport');
      const clientEmailSetting = settings.find((setting) => setting.name === 'patientSupportEmailRecipient');
      setPatientSupportEmailRecipient(clientEmailSetting?.value);
      const reportEditable = canEditReportSetting ? canEditReportSetting.value === 'true' : false;
      setCanEditReport(reportEditable);
      const llmSummarySetting = settings.find((setting) => setting.name === 'llmSummaryEnabled');
      if (llmSummarySetting) {
        setIsLlmSummaryEnabled(llmSummarySetting.value === 'true');
      }
      const logoSetting = settings.find((setting) => setting.name === 'consentPageLogo');
      if (logoSetting) {
        setHeaderLogo(logoSetting.value);
      }
    } catch (error) {
      toastSetErrorNotification(
        axiosErrorToUserMessage(error)
                || 'An error occurred when fetching site info',
      );
    }
  };

  const getLlmSummary = async () => {
    setgenerateLlmSummaryButtonLoading(true);
    try {
      const response = await intakeAxiosWithAuth.post('/llmSummary', { mkey });
      toastSetSuccessNotification('Narrative summary generated');
      setgenerateLlmSummaryButtonLoading(false);
      return response.data;
    } catch (error) {
      toastSetErrorNotification(
        axiosErrorToUserMessage(error)
                || 'An error occurred when fetching the narrative summary.',
      );
      setgenerateLlmSummaryButtonLoading(false);
      return '';
    }
  };

  useEffect(() => {
    if (activeSiteId && mkey) {
      setIsLoading(true);
      fetchData().finally(async () => {
        await fetchAndSetSiteConfigurations();
        setIsLoading(false);
      });
    }
  }, [activeSiteId, mkey]);

  const handleBack = () => {
    navigate(-1); // go back one page
  };

  const updateZoom = (index, newZoom) => {
    setAttachedImages((currentImages) => currentImages.map((img, idx) => (idx === index
      ? { ...img, zoom: newZoom } : img)));
  };

  const renderImages = () => showImages && (
    <Box sx={{
      justifyContent: 'center', display: 'flex', flexWrap: 'wrap', gap: 2, maxWidth: '100%', overflow: 'auto',
    }}
    >
      {attachedImages?.map((image, index) => (
        <ZoomableImage
          key={`attachedImage_${image.urlWithAccessToken}`}
          image={image}
          onZoomChange={(newZoom) => updateZoom(index, newZoom)}
        />
      ))}
    </Box>
  );

  const handleSaveReport = async () => {
    try {
      setIsSaving(true);
      await intakeAxiosWithAuth.put(`/${mkey}/report/save`, {
        reportText: intakeContent,
      });
      toastSetSuccessNotification('Saved');
    } catch (error) {
      toastSetErrorNotification(
        axiosErrorToUserMessage(error) || 'An error occurred while saving the report.',
      );
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <IntakeReviewPageHeader
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...patientInfo}
        onBack={handleBack}
        headerLogo={headerLogo}
      />
      <Container>
        <Box sx={{ padding: '30px' }}>
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh)',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <HtmlTextEntry
                  value={intakeContent}
                  onChange={setIntakeContent}
                  minRows={5}
                  readonly={!canEditReport}
                  onSave={handleSaveReport}
                  onSuccess={toastSetSuccessNotification}
                  isSaving={isSaving}
                  onBack={handleBack}
                />
                {isLlmSummaryEnabled && (
                  <Grid item>
                    <Button
                      text="Generate LLM Summary"
                      onClick={async () => {
                        const { llmSummary } = await getLlmSummary();
                        setSummary(llmSummary);
                      }}
                      loading={generateLlmSummaryButtonLoading}
                      sx={{ mt: 6 }}
                    />
                    <TextField
                      label="LLM Summary"
                      value={summary}
                      onChange={(e) => setSummary(e.target.value)}
                      multiline
                      fullWidth
                      rows={20}
                      variant="outlined"
                      sx={{ mt: 2 }}
                      InputLabelProps={{
                        shrink: summary && summary.length > 0,
                      }}
                    />
                  </Grid>
                )}
              </Grid>
              {canEditReport && templateOptions && (
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box>
                  <OfferedTemplates
                    examTemplates={templateOptions.exam}
                    planTemplates={templateOptions.plan}
                    intakeContent={intakeContent}
                    setIntakeContent={setIntakeContent}
                  />
                </Box>
              </Grid>
              )}
              {signature?.signatureImageSrc
                && <Signature signature={signature} patientID={patientInfo.patientID} />}
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                {attachedImages?.length > 0 && (
                <MuiButton sx={{ mt: 2 }} onClick={() => setShowImages(!showImages)}>
                  {showImages ? 'Hide Images' : `Show Images (${attachedImages.length})`}
                </MuiButton>
                )}
                {renderImages()}
              </Grid>
            </Grid>
          )}
        </Box>
        {patientSupportEmailRecipient ? (
          <BottomNavBar
            patientSupportEmailRecipient={patientSupportEmailRecipient}
            activeSiteId={activeSiteId}
          />
        ) : null}
        <Toast
          open={toastState.open}
          severity={toastState.severity}
          text={toastState.text}
          onClose={toastOnClose}
        />
      </Container>
    </>
  );
}
