import React from 'react';

import PropTypes from 'prop-types';
import {
  TableBody,
  TableRow,
  TableCell,
  Link,
  CircularProgress,
} from '@mui/material';
import useTable from '../components/useTable';
import DeleteSiteButton from '../content/DeleteSiteButton';
import EditSiteButton from '../content/EditSiteButton';
import generateIntakePlanLabel from '../utils/generateIntakePlanLabel';

const header = [
  { id: 'name', label: 'SITE NAME' },
  { id: 'id', label: 'SITE ID' },
  { id: 'intakePlans', label: 'INTAKE PLAN' },
  { id: 'action', label: '' },
];

export default function SiteInfoTable(props) {
  const {
    isAdmin,
    data,
    intakePlansLoading,
    refreshParent,
    setSuccessMessage,
    setErrorMessage,
  } = props;

  const {
    TblContainer,
    TblHead,
    TblPagination,
    currentPageDataSorted,
  } = useTable(data, header, { fn: (items) => items });

  return (
    <>
      <TblContainer>
        <TblHead />
        <TableBody>
          {data && currentPageDataSorted().map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                <Link data-testid="siteName" href={isAdmin ? `/site/${row.id}` : `/site/${row.id}/${row.name}/users`}>
                  {row.name}
                </Link>
              </TableCell>
              <TableCell>{row.id}</TableCell>
              <TableCell>
                {intakePlansLoading[row.id] ? (
                  <CircularProgress size={24} />
                ) : (
                  row.intakePlans.map((plan) => (
                    <div key={plan.referenceCode}>
                      {generateIntakePlanLabel(plan)}
                    </div>
                  ))
                )}
              </TableCell>
              <TableCell>
                <EditSiteButton
                  site={row}
                  refreshParent={refreshParent}
                  setSuccessMessage={setSuccessMessage}
                  setErrorMessage={setErrorMessage}
                />
                <DeleteSiteButton
                  site={row}
                  refreshParent={refreshParent}
                  setSuccessMessage={setSuccessMessage}
                  setErrorMessage={setErrorMessage}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TblContainer>
      <TblPagination />
    </>
  );
}

SiteInfoTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  intakePlansLoading: PropTypes.objectOf(
    PropTypes.bool.isRequired,
  ).isRequired,
  refreshParent: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};
