import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import useAuth from '../hooks/useAuth';
import { axiosIntegrationInstance } from '../services/axios';
import { useToast } from '../components/useToast';
import { convertAxiosErrorToUserFacingMessage } from '../context/ApiClientContext';
import useSiteSettings from '../hooks/useSiteSettings';

export default function FhxSsoLogin() {
  const {
    setAuth,
    setPointClickCareAuth,
  } = useAuth();
  const { updateSiteSettings } = useSiteSettings();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const {
    toastSetErrorNotification,
  } = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const updateAuthAndNavigate = (response) => {
    const { accessToken, pointClickCare, mkey } = response.data;
    const decoded = jwtDecode(accessToken);
    setAuth({ accessToken });
    updateSiteSettings(decoded.siteIds);
    if (state === 'pointClickCare') {
      setPointClickCareAuth(pointClickCare);
    }
    if (mkey) {
      navigate(`/review/${mkey}`);
    } else {
      navigate('/home');
    }
  };

  const verifyCode = async () => {
    setLoading(true);
    try {
      const response = await axiosIntegrationInstance.get(
        `/${state}/auth/callback?code=${code}`,
      );
      updateAuthAndNavigate(response);
    } catch (error) {
      toastSetErrorNotification(convertAxiosErrorToUserFacingMessage(error));
      navigate('/login');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (code) {
      verifyCode();
    }
  }, [code]);

  return (
    <Box
      sx={
        {
          display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',
        }
      }
    >
      {loading ? (
        <CircularProgress />
      ) : null}
    </Box>
  );
}
