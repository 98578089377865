import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Typography, TextField } from '@mui/material';
import Button from '../components/controls/Button';
import Modal from '../components/Modal';
import { useApiClient } from '../context/ApiClientContext';
import basicServiceHandler from '../services/basicServiceHandler';
import { axiosErrorToUserMessage } from '../services/axios';
import '../App.css';

function EditSiteButton(props) {
  const {
    site, refreshParent, setSuccessMessage, setErrorMessage,
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [siteName, setSiteName] = useState(site.name);

  const apiClient = useApiClient();

  const handleConfirmEdit = async () => {
    if (site.id) {
      basicServiceHandler(
        () => apiClient.updateSiteName(site.id, {
          name: siteName,
        }),
        () => {
          refreshParent();
          setSuccessMessage('Site updated successfully');
        },
        (error) => {
          setErrorMessage(
            axiosErrorToUserMessage(error) || 'Error updating site',
          );
        },
      );
      setDialogOpen(false);
    }
  };

  return (
    <>
      <IconButton onClick={() => setDialogOpen(true)}>
        <EditIcon className="table-tool-icon" />
      </IconButton>
      <Modal
        isOpen={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        title="Edit Site"
      >
        <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
          Edit the site name:
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          value={siteName}
          onChange={(e) => setSiteName(e.target.value)}
          sx={{ marginBottom: '1rem' }}
        />
        <Button
          text="Cancel"
          variant="outlined"
          color="primary"
          onClick={() => setDialogOpen(false)}
          sx={{ marginRight: '1rem' }}
        />
        <Button
          text="Done"
          variant="contained"
          color="primary"
          onClick={handleConfirmEdit}
        />
      </Modal>
    </>
  );
}

EditSiteButton.propTypes = {
  site: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  refreshParent: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};

export default EditSiteButton;
