import React, {
  createContext, useContext, useState, useCallback, useMemo,
} from 'react';
import { useApiClient } from './ApiClientContext';
import useSiteSettings from '../hooks/useSiteSettings';
import basicServiceHandler from '../services/basicServiceHandler';
// Create a context
const IntakeDataContext = createContext(null);

// Context provider component
// eslint-disable-next-line react/prop-types
export function IntakeDataProvider({ children }) {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const apiClient = useApiClient();
  const { activeSiteId } = useSiteSettings();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const fetchIntakeData = useCallback(async (
    newPage,
    newRowsPerPage,
    retryCount = 0,
  ) => {
    // Calculate the effective page and rows per page
    const effectivePage = newPage !== undefined ? newPage : page;
    const effectiveRowsPerPage = newRowsPerPage !== undefined ? newRowsPerPage : rowsPerPage;
    // Optionally update state if new values are provided
    if (newPage !== undefined) {
      setPage(newPage);
    }
    if (newRowsPerPage !== undefined) {
      setRowsPerPage(newRowsPerPage);
    }
    setLoading(true);
    basicServiceHandler(
      // API page starts at 1, but our page starts at 0
      () => apiClient.getAllIntakeData(activeSiteId, effectivePage + 1, effectiveRowsPerPage),
      (result) => {
        const { intakes, totalIntakes } = result.data;
        setData(intakes);
        setCount(totalIntakes);

        setLoading(false);
      },
      async (error) => {
        // TODO: This is a temporary fix that should be removed once we resolve the underlying issue
        if (error?.response?.status === 401 && retryCount < 3) {
          // Wait with exponential backoff before retrying
          await new Promise((resolve) => {
            setTimeout(resolve, 1000 * (2 ** retryCount));
          });
          return fetchIntakeData(effectivePage, effectiveRowsPerPage, retryCount + 1);
        }
        setData([]);
        setCount(0);
        setLoading(false);
        return false;
      },
    );
  }, [apiClient, activeSiteId, page, rowsPerPage]);

  return useMemo(() => (
    <IntakeDataContext.Provider value={{
      data, count, loading, fetchIntakeData,
    }}
    >
      {children}
    </IntakeDataContext.Provider>
  ), [data, count, loading, fetchIntakeData, children]);
}

// Hook to use the context
export const useIntakeData = () => useContext(IntakeDataContext);
