import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Typography,
  IconButton,
} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import html2pdf from 'html2pdf.js';
import Toast, { useToast } from '../../components/useToast';
import {
  axiosErrorToUserMessage,
} from '../../services/axios';

export default function Signature({ signature, patientID }) {
  const {
    toastState,
    toastSetSuccessNotification,
    toastSetErrorNotification,
    toastOnClose,
  } = useToast();

  const handlePdfExport = async () => {
    try {
      const htmlContent = `
        <div style="text-align: center; margin: 50px;">
          <p style="margin-bottom: 20px; font-size: 18px; font-weight: bold;">
            ${signature.signatureQuestion || ''}
          </p>
          ${signature.signatureImageSrc !== 'Declined' ? `
            <img src="${signature.signatureImageSrc}" 
                alt="Signature" 
                style="max-width: 200px;margin-top: 20px;display: inline-block; border: 0.5px solid #b5b5b5" />
          ` : ''}
        </div>
     `;

      const options = {
        margin: 5,
        filename: `Signed_Agreement_${patientID}.pdf`,
      };

      await html2pdf().from(htmlContent).set(options).save();
      toastSetSuccessNotification('PDF downloaded successfully');
    } catch (error) {
      toastSetErrorNotification(
        axiosErrorToUserMessage(error)
          || 'An error occurred while generating the PDF.',
      );
    }
  };
  return (
    <Grid
      sx={{
        mt: 2,
        maxWidth: '200px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          margin: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ mr: 1 }}>Signature:</Typography>
        {signature?.signatureImageSrc === 'Declined' ? (
          <Typography sx={{ margin: 1, color: '#b45309' }}>Declined</Typography>
        ) : (
          <>
            <img
              src={signature?.signatureImageSrc}
              alt="Signature"
              style={{
                maxWidth: '200px',
                border: '0.5px solid #b5b5b5',
              }}
            />
            <IconButton>
              <PictureAsPdfIcon
                onClick={() => handlePdfExport()}
              />
            </IconButton>
          </>
        )}
      </Box>
      <Toast
        open={toastState.open}
        severity={toastState.severity}
        text={toastState.text}
        onClose={toastOnClose}
      />
    </Grid>
  );
}

Signature.propTypes = {
  signature: PropTypes.shape({
    signatureImageSrc: PropTypes.string,
    signatureQuestion: PropTypes.string,
  }).isRequired,
  patientID: PropTypes.string,
};

Signature.defaultProps = {
  patientID: '',
};
